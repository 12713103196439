<template>
	<div class="container">
		<policy-content :content="content"></policy-content>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import PolicyContent from '@/components/gadget/PolicyContent';

export default {
	components: {
		PolicyContent
	},
	data() {
		return {
			content: this.getContent()
		};
	},
	methods: {
		getContent() {
			const title = 'ABOUT US - เกี่ยวกับเยลโลไทร์';
			const content = `
				<p>YELLOWTiRE.COM เว็บไซด์ “เยลโลไทร์ดอทคอม” เราเป็นแพลตฟอร์ม ecommerce จำหน่ายยางรถยนต์กว่า 30 ยี่ห้อชั้นนำในประเทศไทย ได้แก่ มิชลิน บริดจสโตน กู๊ดเยียร์ ดันลอป โยโกฮามา  ดีสโตน โตโยไทร์ คอนติเนนทัล บีเอฟกู๊ดริช แม็กซิส พีแรลลี่ ฮันกุก แม็กซิส เน็กเซ็น นิตโตะ ฟอลเก้น อพอลโล ธันเดอเรอร์ โอตานิ เวสเลค ไทแองเกิ้ล จีทีเรเดียล จีที กู๊ดไรด์ นันกัง คัมโฮ เดย์ตัน</p>
				
				<p>คุณสามารถเปรียบเทียบราคายาง ข้อมูลคุณสมบัติ ประเทศผลิต มาตรฐาน มอก. EU LABEL และเปรียบเทียบคุณภาพยางรถยนต์ เพื่อช่วยให้ผู้บริโภคสามารถตัดสินใจซื้อยางรถยนต์ที่ใช่ ได้ยางที่ต้องการและเหมาะสม<p>

				<img src="` + require('@/assets/about-us-decoration.jpg') +`" alt="ABOUT US - เกี่ยวกับเยลโลไทร์" title="ABOUT US - เกี่ยวกับเยลโลไทร์" />
				
				<p>เราได้รับอนุญาตจากกระทรวงพาณิชย์ และผ่านการรับรอง โดยสำนักงานคณะกรรมการคุ้มครองผู้บริโภค(สคบ.) ให้เป็นแพลตฟอร์มอีคอมเมิร์ซ สำหรับยางรถยนต์โดยเฉพาะ ได้รับการแต่งตั้งจากร้านยางชั้นนำให้เป็นผู้แทนจำหน่ายอย่างเป็นทางการ คุณจึงซื้อยางได้มั่นใจ เลือกร้าน เลือกเวลาติดตั้งที่สะดวก</p>

				<p><strong>ที่สุดเรื่องยาง... เช็ค ชอป ชัวร์ YELLOWTiRE.COM</strong><p>
			`;

			return {
				title: title,
				detail: content
			};
		}
	},
	metaInfo() {
        return {
            title: 'เกี่ยวกับเรา | YELLOWTiRE',
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: 'เกี่ยวกับเว็บไซต์ YELLOWTiRE' },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: 'เกี่ยวกับเรา' },
                { vmid: 'og-desc', property: 'og:description', content: 'เกี่ยวกับเว็บไซต์ YELLOWTiRE' },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: location.origin + Helper.metaDefault().logo },
				{ vmid: 'og-title', property: 'og:title', content: 'เกี่ยวกับเรา' },
                { vmid: 'twitter-description', property: 'twitter:description', content: 'เกี่ยวกับเว็บไซต์ YELLOWTiRE' },
                { vmid: 'twitter-title', property: 'twitter:title', content: 'เกี่ยวกับเรา' },
                { vmid: 'twitter-image', property: 'twitter:image', content: location.origin + Helper.metaDefault().logo }
            ]
        }
    }
}
</script>